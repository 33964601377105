import promise from 'es6-promise'
promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
// import './utils/base.js'
import './assets/css/style.css'
import './assets/css/common.css'
import './assets/css/animate.min.css'
import "swiper/dist/css/swiper.min.css";
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
router.beforeEach((to,from,next)=>{
try {
  document.body.style = ''
  setTimeout(()=>{
   document.body.style = ''
  },2000)
} catch (error) {
  
}
 next()
})