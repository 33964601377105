<template>
 <div>
  <div class="index-header clearfix">
   <div class="index-logo fl">
    <a href=""><img src="logo.png" /></a>
   </div>
   <ul class="index-nav fl">
    <li><router-link to="/">首页</router-link></li>
    <li><router-link to="/about">关于品牌</router-link></li>
    <li>
     <router-link to="/products">产品中心</router-link>
     <!--    <div class="menu-children">
      <p>铝合金高架地板</p>
      <p>钢质高架地板</p>
      <p>进口高架地板</p>
     </div>
    </li> -->
    </li>

    <li><router-link to="/cases">经典案例</router-link></li>
    <li><router-link to="/service">技术支持</router-link></li>
    <li><router-link to="/business">环境 与 制造</router-link></li>
    <!-- <li><a href="contact.html">联系我们</a></li> -->
   </ul>
   <!-- <div class="index-header-right fr">
    <a href="" class="search-btn">搜索</a>
    <a href="" class="erweima">
     <div class="erweima-pic"><img src="images/pic1.jpg" /></div>
    </a>
   </div> -->
   <div class="clear"></div>
  </div>
  <!--  <div class="search_sec">
   <a href="#" title="" class="close-search">
    <i class=" la-close"><img src="images/icon5.png" /></i>
   </a>
   <div class="search_field">
    <div class="container">
     <form>
      <input type="text" name="search" placeholder="Search for..." />
      <button type="submit"><img src="images/search2.png" alt="" /></button>
     </form>
    </div>
   </div>
  </div> -->
  <div class="h5-menu">
   <div class="h5-menu-icon" @click="show=!show"><i class="el-icon-menu"></i></div>
   <ul @click="show=false"  :class="{'ulshow':show}">
    <li  ><router-link to="/">首页</router-link></li>
    <li><router-link to="/about">关于品牌</router-link></li>
    <li><router-link to="/products">产品中心</router-link></li>
    <li><router-link to="/cases">经典案例</router-link></li>
    <li><router-link to="/service">技术支持</router-link></li>
    <li><router-link to="/business">环境 与 制造</router-link></li>
    <!-- <li><router-link to="/contact">联系我们</router-link></li> -->
   </ul>
  </div>
 </div>
</template>

<script>
 export default{
  data() {
   return {
    show:false,
   }
  },
  methods:{
   
  }
 }
</script>

<style scoped>
.index-logo img {
 width: 60px;
}
.index-nav li {
 position: relative;
}
.menu-children {
 position: absolute;
 width: 150px;
 left: 0;
 top: 90px;
 color: white;
 background-color: rgba(0, 0, 0, 0.3);
}
.h5-menu{
 position: fixed;
 right: .1rem;
 top: .1rem;
 z-index: 999;
 color: #fff;
 text-align: center;
 display: none;
}
.h5-menu-icon{
     background-color: #fff;
     width: .25rem;
     height: .25rem;
     align-items: center;
     display: flex;
     justify-content: center;
     border: 1px solid #fff;
     border-radius: 100rem;
     color: #111;
        margin: 0 auto;
        }
.h5-menu ul{
    height: 0;
    overflow: hidden;
    width: .8rem;
    left: -.55rem;
    top: .3rem;
    text-align: right;
    position: absolute;
    transition: all 300ms;
}
.h5-menu  li a{
 font-size: .12rem;
 /* color: white; */
 color: #dad6d6;
 text-shadow: 0px 5px 15px #dad6d6;
}
.h5-menu .ulshow{
 height: 2rem;
}
@media all and (max-width: 900px) {
 .index-header {
  padding: 0;
  margin: 0;
  display: none;
 }
 .h5-menu{
  display: block;
 }
 .h5-menu ul{
  display: block;
 }
}
</style>
