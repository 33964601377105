<template>
 <div class="home">
  <div id="bloc">
   <article id="index">
    <div class="banner-position">
     <div class="index-banner swiper-container">
      <div class="swiper-wrapper">
       <div class="swiper-slide" v-for="(img,index) in bannerImgList" :key='index'><img :src="img" /></div>
      </div>
      <!-- Add Navigation -->
      <div class="swiper-button-prev swiper-button-white"></div>
      <div class="swiper-button-next swiper-button-white"></div>
     </div>
     <div class="banner-wave">
      <svg viewBox="0 0 120 28">
       <defs>
        <filter id="goo">
         <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />

         <feColorMatrix
          in="blur"
          mode="matrix"
          values="
   			
   							   1 0 0 0 0  
   							
   							   0 1 0 0 0  
   							
   							   0 0 1 0 0  
   							
   							   0 0 0 13 -9"
          result="goo"
         />

         <xfeBlend in="SourceGraphic" in2="goo" />
        </filter>

        <path id="wave" d="M 0,10 C 30,10 30,15 60,15 90,15 90,10 120,10 150,10 150,15 180,15 210,15 210,10 240,10 v 28 h -240 z" />
       </defs>

       <use id="wave3" class="wave" xlink:href="#wave" x="0" y="-2"></use>

       <use id="wave2" class="wave" xlink:href="#wave" x="0" y="0"></use>


      </svg>
     </div>
    </div>
    <div class="clear"></div>
    <!--about-->
    <div class="index-about">
     <div class="index-con clearfix">
      <div class="index-about-text fl">
       <div class="index-title">
        <h3 class="scroll-animate" data-effect="fadeInUpSmall"></h3>
        <h2 class="scroll-animate" data-delay="0.2" data-effect="fadeInUpSmall">关于我们</h2>
        <span></span>
       </div>
       <p class="scroll-animate" data-delay="0.6" data-effect="fadeInUpSmall">
        {{aboutText}}
       </p>
       <div class="index-more"><router-link to="/contact">联系我们</router-link></div>
      </div>
      <div class="index-about-pic fr">
       <!-- <a href=""><img src="/images/home-ditu.jpg" class="scroll-animate classGo" data-Tclass="go" /></a> -->
       <a href=""><img src="/images/home-ditu.jpg" class="scroll-animate classGo" data-Tclass="go" /></a>
      </div>
     </div>
     <div class="wave_box1"><img src="../assets/images/wavs.gif" /></div>
    </div>
    <div class="clear"></div>
    <div class="position-yuan clearfix"><div class="bg scr-el" data-v="1" data-speed-x="12" data-offset="150 "></div></div>
    <div class="clear"></div>
    <!--产品-->
    <div class="index-product clearfix">
     <div class="index-con clearfix">
      <div class="index-title">
       <h3></h3>
       <h2>产品</h2>
      </div>
      <ul class="clearfix index-product-list clearfix">
       <li v-for="(item,index) in product" :key='index'>
         <router-link :to="item.url">
         <em><img :src="item.img" /></em>
         <div class="text">
          <h2>{{item.name}}</h2>
          <span><img src="../assets/images/jt.png" /></span>
         </div>
        </router-link>
       </li>
      </ul>
     </div>
    </div>
    <div class="clear"></div>

    <!--news-->
    <div class="index-news" style="margin-bottom: 50px;">
     <div class="index-con clearfix">
      <div class="index-title">
       <h3></h3>
       <h2>案例</h2>
      </div>
      <ul class="index-news-list clearfix">
       <li class="trs02 an_up"  v-for="(item,index) in caseList" :key='index'>
        <router-link to="/cases">
         <em><img :src="item.img" /></em>
         <div class="text">
          <span></span>
          <p>{{item.name}}</p>
         </div>
         </router-link>
       </li>
      </ul>
      <div class="clear"></div>
     </div>
    </div>
  <!--   <div class="p-title">
     	<div class="t">合作伙伴</div>
     	<i class="s"></i>
     </div> -->
     <div class="index-title">
      <h3></h3>
      <h2>合作伙伴</h2>
     </div>
     <div class="item-div2 item-div"  style="padding-bottom: 60px;">
     	 <img style="width: 90%;margin: 0 auto;" :src="huobanImgUrl" />
     </div>
    <!--footer-->
    <indexfotter></indexfotter>
    <div class="clear"></div>
   </article>
  </div>
 </div>
</template>

<script>
// import Swiper from 'swiper';
import Swiper from 'swiper/dist/js/swiper.min.js';
 import indexfotter from '../components/indexfotter.vue'
import axios from 'axios'
 // import homdata from 'data/home'
export default {
 name: 'Home',
 components: {
  indexfotter
 },
  data() {
  return {
   bannerImgList:[],
   aboutText:'',
   product:[],
   caseList:[],
   huobanImgUrl:''
  }
 },
async mounted() {
  const res =await axios.get('./data/hom2.json')
  this.bannerImgList = res.data.bannerImgList
  this.aboutText = res.data.aboutText
  this.product = res.data.product
  this.caseList = res.data.case
  this.huobanImgUrl = res.data.huobanImgUrl
  try {
     var swiper = new Swiper('.swiper-container', {
   sspeed: 600,
  parallax: true,   
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  });
  scrolly.init({
  	wrapper: '#bloc',
  	targets: '.scr-el',
  	bgParallax: true,
  	wrapperSpeed: 0.08
  });
  } catch (error) {
    
  }
 
 }
};
</script>
