import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/business',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/business.vue')
  },
  {
    path: '/cases',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/cases.vue')
  },
  {
    path: '/contact',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "about" */ '../views/product/index.vue'),
    children:[{
     path: 'alloy',
     name: 'alloy',
     component: () => import(/* webpackChunkName: "about" */ '../views/product/alloy.vue'),
    },{
     path: 'steel',
     name: 'steel',
     component: () => import(/* webpackChunkName: "about" */ '../views/product/steel.vue'),
    },{
     path:'overhead',
     name: 'overhead',
     component: () => import(/* webpackChunkName: "about" */ '../views/product/overhead.vue'),
    },{
     path:'area',
     name: 'area',
     component: () => import(/* webpackChunkName: "about" */ '../views/product/area.vue'),
    },{
     path: '/',
     name: 'alloy',
     redirect:'alloy'
    }]
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
  },
  {
    path: '/service',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/service.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  }
  
]

const router = new VueRouter({ 
  mode: 'history',
  routes
})

export default router
